<template>
  <modal name="modal-admin-order-alarm" class="modal-inner modal-admin-order-alarm" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <LoadingSpinner :isLoading="isLoading" />
    <span class="close-button" @click="hide('modal-admin-order-alarm')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      Beställ <span v-if="customer">: {{ customer.name }} </span><span v-if="unit">- {{ unit.name }}</span>
    </div>
    <ValidationObserver ref="formAdminOrderAlarm">
      <div class="modal-body">
        <div class="flex flex-wrap border-b border-secondary-500 mx-2 pb-4 mb-5" v-if="!order_alarm._id">
          <div class="w-full px-2">
            <div class="clearfix">
              <div class="float-right">
                <label class="btn-blue cursor-pointer">
                  Beställningsunderlag
                  <BaseIcon icon="file-pdf" class="ml-1" />
                  <input type="file" accept="application/pdf" ref="pdfOrder" @change="fileChangeCreate()" class="hidden" />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-4">
          <div class="w-full text-gray-600 text-md font-bold px-2 pb-3 font-serif">
            Beställarens uppgifter
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="order_alarm.customer_info.contact_name" type="text" field_name="Namn" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="order_alarm.customer_info.contact_email" type="text" field_name="E-post" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="order_alarm.customer_info.contact_phone" type="text" field_name="Telefonnummer" />
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="3" v-model="order_alarm.customer_info.company_name" field_name="Företagsnamn" />
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="3" v-model="order_alarm.customer_info.invoice_detail" field_name="Fakturaadress" />
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full text-gray-600 text-md font-bold px-2 pb-3 font-serif">
            Generella frågor
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Sker en del av arbetet utomhus, eller genom hembesök?</label>
                <input type="text" v-model="order_alarm.customer_info.outdoor_work" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Vill ni kunna larma mellan kollegor?</label>
                <input type="text" v-model="order_alarm.customer_info.alert_members" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Vill ni veta vart i huset man befinner sig vid larmning?</label>
                <input type="text" v-model="order_alarm.customer_info.indoor_tracking" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Vill ni att larm ska aktiveras om personen som bär larmet ramlar (Mandown)?</label>
                <input type="text" v-model="order_alarm.customer_info.man_down_alert" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Vill ni få SMS eller Mejl om ett larm har aktiverats</label>
                <textarea rows="3" v-model="order_alarm.customer_info.email_sms_detail" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full text-gray-600 text-md font-bold px-2 pb-3 font-serif">
            Larmunderlag
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Antal personer som ska använda larmet</label>
                <input type="text" v-model="order_alarm.customer_info.total_user" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Vad heter personen/gruppen?</label>
                <input type="text" v-model="order_alarm.customer_info.person_group_names" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Personens/ gruppens arbetsuppgifter</label>
                <input type="text" v-model="order_alarm.customer_info.work_task" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Arbetsplatsens ort</label>
                <input type="text" v-model="order_alarm.customer_info.working_place" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Finns det idag någon hot- eller riskbild som du vill att larmcentralen skall veta?</label>
                <input type="text" v-model="order_alarm.customer_info.risk_detail" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <ValidationProvider name="" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-xs pb-2 font-serif">Övrig information</label>
                <textarea rows="3" v-model="order_alarm.customer_info.other_info" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="order_alarm.seller" :options="sellers" nameSelector="name" optionSelector="_id" field_name="Säljare" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="order_alarm.alarm_center" :options="[{ name: 'Ingen', _id: '-' }, ...alarm_centers]" nameSelector="name" optionSelector="_id" field_name="Larmcentral" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="order_alarm.sim_card" :options="sim_operators" field_name="Simkort" rules="required" />
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="order_alarm.risk_profile" :options="['Hotlarm', 'Nödlarm']" rules="required" field_name="Riskprofil" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="order_alarm.from_address" type="text" rules="required" field_name="Utgår från adress" />
          </div>
          <div class="w-full px-2 py-2">
            <BaseTextarea rows="2" v-model="order_alarm.alarms_name" field_name="Namn på produkter" rules="required" />
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="5" v-model="order_alarm.comment" field_name="Kommentar till orderhantering" />
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="5" v-model="order_alarm.info_securcloud" field_name="Information SecurCloud" />
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="5" v-model="order_alarm.contact_person" field_name="Kontaktpersoner" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseInput v-model="order_alarm.alarm_password" type="text" field_name="Lösenord" rules="required" />
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full md:w-1/3 px-2 py-2">
            <label class="inline-flex items-center mt-3"> <input v-model="order_alarm.call_button" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">Internlarm</span> </label>
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <label class="inline-flex items-center mt-3"> <input v-model="order_alarm.timer_alarm" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">Timeralarm</span> </label>
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <label class="inline-flex items-center mt-3"> <input v-model="order_alarm.man_down" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">Mandown</span> </label>
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <label class="inline-flex items-center mt-3"> <input v-model="order_alarm.nine_vib" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">9-Vibb</span> </label>
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full md:w-1/3 px-2 py-2">
            <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">SMS</div>
            <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in order_alarm.sms_numbers" :key="i">
              <div class="w-11/12">
                <ValidationProvider :name="'SMS Nummer ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <input v-model="n.msisdn" type="text" @keypress="isNumber($event)" maxlength="15" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 py-2" />
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <button class="w-1/12 text-red-500 pl-2" @click.prevent="removeSMSInput(i)"><BaseIcon icon="minus-circle" /></button>
            </div>
            <div class="w-full">
              <button class="btn-blue-outline mt-2" @click.prevent="addSMSInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
            </div>
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <div class="flex flex-col">
              <div class="w-full text-gray-600 text-sm font-bold pb-5 font-serif">
                Produkt
              </div>
              <div class="w-full flex flex-wrap mb-5 font-bold">
                <div class="w-1/3 text-sm">Typ</div>
                <div class="w-1/3 text-sm pl-2">Kvantitet</div>
                <div class="w-1/3 text-sm pl-2">Prissätta</div>
              </div>
              <div class="w-full flex flex-wrap" v-for="p in order_alarm.products" :key="p.id">
                <div class="w-1/3 text-sm">{{ p.name }}</div>
                <div class="w-1/3 pl-2">
                  <ValidationProvider :name="p.name" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" v-model.number="p.qty" min="0" @keypress="isNumber($event)" @blur="calculateTotalPrice()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="w-1/3 pl-2">
                  <ValidationProvider :name="p.name" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" v-model.number="p.price" min="0" @keypress="isNumber($event)" @blur="calculateTotalPrice()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model.number="order_alarm.contract_period" @keypress="isNumber($event)" type="text" field_name="Avtalsperiod" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model.number="order_alarm.setup_fee" @keypress="isNumber($event)" type="text" field_name="Uppläggningsavgift" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model.number="order_alarm.rental_month_cost" @keypress="isNumber($event)" type="text" field_name="Hyreskostnad" rules="required" disabled />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="order_alarm.payment_terms" :options="['Årsvis', 'Halv år', 'Kvartal', 'Månad']" rules="required" field_name="Betalningsvillkor" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <ValidationProvider name="Avtalsstart" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Avtalsstart<b class="text-red-500 ml-1">*</b></label>
                <date-picker :lang="date_picker_lang" :key="date_picker_langKey" class="mt-2" v-model="order_alarm.contract_start" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false"></date-picker>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full px-2 py-2">
            <BaseTextarea rows="4" v-model="order_alarm.special_terms" field_name="Särskilda villkor" rules="required" />
          </div>
          <div class="w-full px-2 py-2">
            <BaseTextarea rows="1" v-model="order_alarm.delivery_period" field_name="När behöver beställningen vara klar" rules="" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button v-if="order_alarm.agreement_number == undefined || order_alarm.agreement_number == ''" class="btn-green-outline px-3 py-2 float-left" @click="saveOrder()">Spara<BaseIcon icon="save" class="ml-1" /></button>
          <button v-if="order_alarm.agreement_number == undefined || order_alarm.agreement_number == ''" class="btn-blue px-3 py-2 float-right" @click="orderNewAlarm()">Beställ<BaseIcon icon="arrow-right" class="ml-1" /></button>
          <button v-if="order_alarm.agreement_number" class="btn-blue px-3 py-2 float-right" @click="updateOrderLog()">Uppdatera<BaseIcon icon="save" class="ml-1" /></button>
        </div>
      </div>
    </ValidationObserver>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  name: "adminOrderAlarmModal",
  components: {
    DatePicker,
  },
  props: {
    unit_id: {
      required: true,
      default: null,
    },
    sellers: {
      required: true,
      default: [],
    },
    alarm_centers: {
      required: true,
      default: [],
    },
    customer: {
      required: true,
      default: null,
    },
    unit: {
      required: true,
      default: null,
    },
    updateInfo: {
      default: null,
    },
  },

  watch: {
    unit(newVal) {
      if (newVal) {
        this.order_alarm.seller = newVal.seller_id;
        this.order_alarm.alarm_center = newVal.alarm_central;
        this.order_alarm.from_address = `${newVal.address} ${newVal.zip_code} ${newVal.city}`;
        this.selected_pdf_file = null;
      }
    },
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },

  data() {
    return {
      isLoading: false,
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"],
      selected_pdf_file: null,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
      order_alarm: {
        process_order: true,
        seller: "",
        contract_start: "",
        contract_period: "",
        setup_fee: "",
        rental_month_cost: "",
        payment_terms: "",
        special_terms: "",
        sim_card: "",
        alarm_center: "",
        risk_profile: "",
        from_address: "",
        alarms_name: "",
        comment: "",
        info_securcloud: "",
        contact_person: "",
        alarm_password: "",
        call_button: false,
        timer_alarm: false,
        man_down: false,
        nine_vib: false,
        sms_numbers: [],
        delivery_period: "",
        products: [
          { name: "SRT306", type: "srt306", qty: 0, price: 0 },
          { name: "SRT306i", type: "srt306i", qty: 0, price: 0 },
          { name: "SRT306 G2", type: "srt306_g2", qty: 0, price: 0 },
          { name: "SRT326", type: "srt326", qty: 0, price: 0 },
          { name: "SRT330", type: "srt330", qty: 0, price: 0 },
          { name: "SRT334", type: "srt334", qty: 0, price: 0 },
          { name: "SRT405", type: "srt405", qty: 0, price: 0 },
          { name: "SRT405i", type: "srt405i", qty: 0, price: 0 },
          { name: "SRT406", type: "srt406", qty: 0, price: 0 },
          { name: "SRT406i", type: "srt406i", qty: 0, price: 0 },
          { name: "SRT430", type: "srt430", qty: 0, price: 0 },
          { name: "SRT278", type: "srt278", qty: 0, price: 0 },
          { name: "SRT341", type: "srt341", qty: 0, price: 0 },
          { name: "TWIG Neo", type: "twig_neo", qty: 0, price: 0 },
          { name: "TWIG Neo SRD", type: "twig_ips", qty: 0, price: 0 },
          { name: "TWIG Neo MD", type: "twig_neo_md", qty: 0, price: 0 },
          { name: "TWIG Neo SRD MD", type: "twig_neo_srd_md", qty: 0, price: 0 },
          { name: "TWIG One", type: "twig_one_gps", qty: 0, price: 0 },
          { name: "TWIG One SRD", type: "twig_one_srd", qty: 0, price: 0 },
          { name: "TWIG One EX", type: "twig_one_ex", qty: 0, price: 0 },
          { name: "TWIG Beacon", type: "twig_beacon", qty: 0, price: 0 },
          { name: "TWIG Beacon EX", type: "twig_beacon_ex", qty: 0, price: 0 },
          { name: "Anchor Beacon 2", type: "anchorbeacon", qty: 0, price: 0 },
          { name: "EMERIT WG100", type: "emerit_wg100", qty: 0, price: 0 },
          { name: "TELTONIKA FMC003", type: "teltonika_fmc003", qty: 0, price: 0 },
        ],
        // pdf info
        customer_info: {
          // contact
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          company_name: "",
          invoice_detail: "",
          // general
          outdoor_work: "",
          alert_members: "",
          indoor_tracking: "",
          man_down_alert: "",
          email_sms_detail: "",
          // alarm plan
          total_user: "",
          person_group_names: "",
          work_task: "",
          working_place: "",
          risk_detail: "",
          other_info: "",
        },
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    processOrderPDF() {
      if (this.selected_pdf_file != null) {
        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        let formData = new FormData();

        formData.append("pdf", this.selected_pdf_file);

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/admin-order/pdf`, formData, headers)
          .then((response) => {
            // eslint-disable-line
            // customer info
            if (response.data.contact_name != "") this.order_alarm.customer_info.contact_name = response.data.contact_name;
            if (response.data.contact_email != "") this.order_alarm.customer_info.contact_email = response.data.contact_email;
            if (response.data.contact_phone != "") this.order_alarm.customer_info.contact_phone = response.data.contact_phone;
            if (response.data.company_name != "") this.order_alarm.customer_info.company_name = response.data.company_name;
            if (response.data.invoice_detail != "") this.order_alarm.customer_info.invoice_detail = response.data.invoice_detail;
            // general info
            if (response.data.outdoor_work != "") this.order_alarm.customer_info.outdoor_work = response.data.outdoor_work;
            if (response.data.alert_members != "") this.order_alarm.customer_info.alert_members = response.data.alert_members;
            if (response.data.indoor_tracking != "") this.order_alarm.customer_info.indoor_tracking = response.data.indoor_tracking;
            if (response.data.man_down_alert != "") this.order_alarm.customer_info.man_down_alert = response.data.man_down_alert;
            if (response.data.email_sms_detail != "") this.order_alarm.customer_info.email_sms_detail = response.data.email_sms_detail;
            // alarm plan
            if (response.data.total_user != "") this.order_alarm.customer_info.total_user = response.data.total_user;
            if (response.data.person_group_names != "") this.order_alarm.customer_info.person_group_names = response.data.person_group_names;
            if (response.data.work_task != "") this.order_alarm.customer_info.work_task = response.data.work_task;
            if (response.data.working_place != "") this.order_alarm.customer_info.working_place = response.data.working_place;
            if (response.data.risk_detail != "") this.order_alarm.customer_info.risk_detail = response.data.risk_detail;
            if (response.data.other_info != "") this.order_alarm.customer_info.other_info = response.data.other_info;

            if (response.data.alarm_addr != "") this.order_alarm.from_address = response.data.alarm_addr;
            if (response.data.alarm_password != "") this.order_alarm.alarm_password = response.data.alarm_password;
            if (response.data.contact_person_1 != "" || response.data.contact_person_2 != "" || response.data.contact_person_3 != "") this.order_alarm.contact_person = `${response.data.contact_person_1.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")}\n${response.data.contact_person_2.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")}\n${response.data.contact_person_3.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")}`;

            this.selected_pdf_file = null;
            this.$refs.pdfOrder.value = null;
            this.handleSuccess(`Gjort`);
          })
          .catch((error) => {
            this.handleError(error);
            this.selected_pdf_file = null;
            this.$refs.pdfOrder.value = null;
          });
      }
    },

    orderNewAlarm() {
      this.$refs.formAdminOrderAlarm.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          this.isLoading = true;

          this.order_alarm.seller_name = this.getSellerName(this.order_alarm.seller);

          if (this.order_alarm._id) {
            let data = {
              process_order: this.order_alarm.process_order,
              seller: this.order_alarm.seller,
              seller_name: this.order_alarm.seller_name,
              contract_start: this.order_alarm.contract_start,
              contract_period: this.order_alarm.contract_period,
              setup_fee: this.order_alarm.setup_fee,
              rental_month_cost: this.order_alarm.rental_month_cost,
              payment_terms: this.order_alarm.payment_terms,
              special_terms: this.order_alarm.special_terms,
              sim_card: this.order_alarm.sim_card,
              alarm_center: this.order_alarm.alarm_center,
              risk_profile: this.order_alarm.risk_profile,
              from_address: this.order_alarm.from_address,
              alarms_name: this.order_alarm.alarms_name,
              comment: this.order_alarm.comment,
              info_securcloud: this.order_alarm.info_securcloud,
              contact_person: this.order_alarm.contact_person,
              alarm_password: this.order_alarm.alarm_password,
              call_button: this.order_alarm.call_button,
              timer_alarm: this.order_alarm.timer_alarm,
              man_down: this.order_alarm.man_down,
              nine_vib: this.order_alarm.nine_vib,
              sms_numbers: this.order_alarm.sms_numbers,
              delivery_period: this.order_alarm.delivery_period,
              products: this.order_alarm.products,
              customer_info: this.order_alarm.customer_info,
            };
            // eslint-disable-next-line
            let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/unit/${this.order_alarm.unit_id}/admin/order-alarm/${this.order_alarm._id}`, data);
          } else {
            // eslint-disable-next-line
            let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/unit/${this.unit_id}/admin/order-alarm`, this.order_alarm);
          }

          this.$emit("orderUpdated");

          if (this.order_alarm.process_order) this.handleSuccess("Beställning skapad");
          else this.handleSuccess("Beställningen sparad");

          this.isLoading = false;

          this.hide("modal-admin-order-alarm");
          this.resetForm();
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },

    saveOrder() {
      this.$refs.formAdminOrderAlarm.validate().then(async (success) => {
        if (!success) {
          return;
        }

        this.order_alarm.process_order = false;
        this.orderNewAlarm();
      });
    },

    updateOrderLog() {
      this.$refs.formAdminOrderAlarm.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          this.isLoading = true;

          let data = {
            seller: this.order_alarm.seller,
            contract_start: this.order_alarm.contract_start,
            contract_period: this.order_alarm.contract_period,
            setup_fee: this.order_alarm.setup_fee,
            rental_month_cost: this.order_alarm.rental_month_cost,
            payment_terms: this.order_alarm.payment_terms,
            special_terms: this.order_alarm.special_terms,
            sim_card: this.order_alarm.sim_card,
            alarm_center: this.order_alarm.alarm_center,
            risk_profile: this.order_alarm.risk_profile,
            from_address: this.order_alarm.from_address,
            alarms_name: this.order_alarm.alarms_name,
            comment: this.order_alarm.comment,
            info_securcloud: this.order_alarm.info_securcloud,
            contact_person: this.order_alarm.contact_person,
            alarm_password: this.order_alarm.alarm_password,
            call_button: this.order_alarm.call_button,
            timer_alarm: this.order_alarm.timer_alarm,
            man_down: this.order_alarm.man_down,
            nine_vib: this.order_alarm.nine_vib,
            sms_numbers: this.order_alarm.sms_numbers,
            delivery_period: this.order_alarm.delivery_period,
            products: this.order_alarm.products,
            customer_info: this.order_alarm.customer_info,
          };

          // eslint-disable-next-line
          let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/order-alarm/save/${this.order_alarm._id}`, data);

          this.$emit("orderUpdated");

          this.handleSuccess("Beställning uppdaterad");

          this.isLoading = false;

          this.hide("modal-admin-order-alarm");
          this.resetForm();
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },

    setupOrderForm() {
      if (this.updateInfo) {
        this.order_alarm = _.cloneDeep(this.updateInfo);
        this.order_alarm.process_order = true;
      }
    },

    fileChangeCreate() {
      if (this.$refs.pdfOrder.files.length > 0) {
        this.selected_pdf_file = this.$refs.pdfOrder.files[0];
        this.processOrderPDF();
      } else {
        this.selected_pdf_file = null;
      }
    },

    calculateTotalPrice() {
      let totalPrice = this.order_alarm.products.reduce((a, c) => a + c.qty * c.price, 0) || 0;

      this.order_alarm.rental_month_cost = totalPrice;
    },

    resetForm() {
      this.order_alarm = {
        process_order: true,
        seller: "",
        contract_start: "",
        contract_period: "",
        setup_fee: "",
        rental_month_cost: "",
        payment_terms: "",
        special_terms: "",
        sim_card: "",
        alarm_center: "",
        risk_profile: "",
        from_address: "",
        alarms_name: "",
        comment: "",
        info_securcloud: "",
        contact_person: "",
        alarm_password: "",
        call_button: false,
        timer_alarm: false,
        man_down: false,
        nine_vib: false,
        sms_numbers: [],
        delivery_period: "",
        products: [
          { name: "SRT306", type: "srt306", qty: 0, price: 0 },
          { name: "SRT306 G2", type: "srt306_g2", qty: 0, price: 0 },
          { name: "SRT306i", type: "srt306i", qty: 0, price: 0 },
          { name: "SRT405", type: "srt405", qty: 0, price: 0 },
          { name: "SRT406", type: "srt406", qty: 0, price: 0 },
          { name: "SRT406i", type: "srt406i", qty: 0, price: 0 },
          { name: "SRT326", type: "srt326", qty: 0, price: 0 },
          { name: "SRT330", type: "srt330", qty: 0, price: 0 },
          { name: "SRT334", type: "srt334", qty: 0, price: 0 },
          { name: "TWIG Neo", type: "twig_neo", qty: 0, price: 0 },
          { name: "TWIG Neo SRD", type: "twig_ips", qty: 0, price: 0 },
          { name: "TWIG One GPS", type: "twig_one_gps", qty: 0, price: 0 },
          { name: "TWIG One SRD", type: "twig_one_srd", qty: 0, price: 0 },
          { name: "TWIG One EX", type: "twig_one_ex", qty: 0, price: 0 },
          { name: "TWIG Beacon", type: "twig_beacon", qty: 0, price: 0 },
        ],
        // pdf info
        customer_info: {
          // contact
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          company_name: "",
          invoice_detail: "",
          // general
          outdoor_work: "",
          alert_members: "",
          indoor_tracking: "",
          man_down_alert: "",
          email_sms_detail: "",
          // alarm plan
          total_user: "",
          person_group_names: "",
          work_task: "",
          working_place: "",
          risk_detail: "",
          other_info: "",
        },
      };

      if (this.unit) {
        this.order_alarm.seller = this.unit.seller_id;
        this.order_alarm.alarm_center = this.unit.alarm_central;
        this.order_alarm.from_address = `${this.unit.address} ${this.unit.zip_code} ${this.unit.city}`;
      }

      this.$refs.formAdminOrderAlarm.reset();
    },

    addSMSInput() {
      this.order_alarm.sms_numbers.push({ msisdn: "" });
    },

    removeSMSInput(i) {
      this.order_alarm.sms_numbers.splice(i, 1);
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getSellerName(seller_id) {
      let s = _.find(this.sellers, { _id: seller_id });
      if (s) return s.name;
      else return "";
    },

    show(id) {
      this.$modal.show(id);
    },

    hide(id) {
      this.$refs.formAdminOrderAlarm.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
